import React from 'react'
import '../styles//modules/community.scss'

const Community = () => {
  return (
    <div>
      <meta http-equiv="refresh" content="0; url=https://couchbase.com/community"/>
      <link rel="canonical" href="https://couchbase.com/community"/>
    </div>
  )
}

export default Community
